<template>
    <div class="member-class">
        <van-cell-group :border="false" class="my-group">
            <van-cell title="会员姓名" :value="information.memberName" />
            <van-cell title="会员电话" :value="information.mobile" />
            <van-cell title="性别" :value="information.sex === '1' ? '男' : information.sex === '2' ? '女' : ''" />
            <van-cell title="会员生日" :value="information.birthDate" />
        </van-cell-group>
        <van-sticky>
            <van-tabs @click="onClick" v-model="active" animated class="my-tabs">
                <van-tab title="充值记录"></van-tab>
                <van-tab title="套餐预警"></van-tab>
            </van-tabs>
        </van-sticky>
        <!-- 充值 -->
        <div v-if="active === 0" class="my-tab0" >
           <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                    <div class="my-bill-list">
                        <div class="item" v-for="item in list" :key="item.id">
                            <div class="pic">
                                <van-image class="money-image" fit="cover" :src="imgMoney" alt=""/>
                                <div class="date-type">
                                    <div class="date">
                                        {{item.billDatetime | time}}
                                    </div>
                                </div>
                            </div>
                            
                            <div class="money">
                                ￥{{item.payAmount}}
                            </div>
                        </div>
                    </div>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
                <no-data v-show="showData"/>
            </van-pull-refresh>
        </div>
        <!-- 课程套餐 -->
        <div v-else class="my-tab1" >
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                    <div class="my-course-list">
                        <my-voucher v-for="item in list" :key="item.id" :item="item" :show-tip="item.status">
                            <div slot="content">
                                <div class="info color">
                                    <div class="label">总鞍时</div>
                                    <div class="value">{{item.cardHoursTotal}}鞍时</div>
                                </div>
                                <div class="info color">
                                    <div class="label">已使用</div>
                                    <div class="value">{{item.cardHoursUsed}}鞍时</div>
                                </div>
                                <div class="info color">
                                    <div class="label">已过期</div>
                                    <div class="value">{{item.cardHoursOverdue}}鞍时</div>
                                </div>
                                <div class="info color last">
                                    <div class="label">可用</div>
                                    <div class="value">{{item.cardHoursSurplus}}鞍时</div>
                                </div>
                            </div>
                            <div class="btn" slot="bottom">
                                <div class="tip ellipsis" v-if="item.status !== 0">
                                    {{item.dueDay ? `课程套餐还有${item.dueDay}天到期` : ''}}
                                </div> 
                            </div>   
                            
                        </my-voucher>
                    </div>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
                <no-data v-show="showData"/>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: '',
            active: 0,
            imgMoney: require('@/assets/img/money.png'),
            srcType: ['卡.劵订单', '商城订单', '课程订单', '充值订单'],
            billType: ['充值', '退款', '提现', '消费余额', '消费现金'],
            information: {}
        }
    },
    filters:{
        time(val) {
            let str = val
            if(str) {
                if(str.indexOf('T') !== -1) {
                    str = str.replace('T' , ' ')
                }
            }
            return str
        }
    },
    methods: {
        init() {
            this.initList()
        },
        // 
        onClick() {
            if(this.active === 1){
                this.api = '';
                this.init()
            }
             this.model = {}
            if(this.active === 1){
                this.model.billCurriculumTimeId = this.$route.query.id
                this.model.memberId = this.$route.query.memberId
                this.api = 'person/bill/cardOrder/cardOrder-earlyWarning';
                this.init()
            }else if(this.active === 0) {
                this.model.memberId = this.$route.query.memberId
                this.api = 'person/bill/memberRecharge/memberRechargel-list';
                this.init()
            }
        },
        getDetail() {
            this.$help.loading()
            this.$api.post('person/base/member/member-show', {id: this.$route.query.memberId}).then(res=>{
                console.log(res)
                this.information = res
            })
            .finally(this.$help.loaded)
        },
    },
    mounted() {
        this.getDetail()
        this.onClick()
    }
}
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .member-class{
        .my-tabs{
            .van-tabs__line{
                background-color: $main;
                width: 80px;
            }
        }
        .my-tab0{
            .my-pull-refresh{
                padding: 20px 20px 0;
                .my-bill-list{
                    .item{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;
                        background-color: #fff;
                        padding:10px 20px;
                        border-radius: 9px;
                        box-shadow: 0 0 9px 6px $boxShadow;
                        .money-image{
                            width: 50px;
                            height: 50px;
                        }
                        .pic{
                            display: flex;
                            align-items: center;
                        }
                        .date-type{
                            .type{
                                font-size: 14px;
                            }
                            .date{
                                color: $gray;
                            }
                        }
                        .money{
                            font-size: 14px;
                            font-weight: bold;
                            color: $main;
                        }
                    }
                }
            }
            .no-data{
                top: 66%;
            }
        }
        .my-tab1{
            .my-pull-refresh{
                margin-top: 20px;
                padding: 0 20px
            }
            .no-data{
                top: 66%;
            }
        }
    }

</style>